/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:
* 'wght' (range from 200.0 to 700.0)
*/
@font-face {
  font-family: 'GeneralSans-Variable';
  src: 
    url('/fonts/GeneralSans-Variable.woff2') format('woff2'),
    url('/fonts/GeneralSans-Variable.woff') format('woff'),
    url('/fonts/GeneralSans-Variable.ttf') format('truetype');
    font-weight: 200 700;
    font-display: swap;
    font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:
* 'wght' (range from 300.0 to 700.0)
*/
@font-face {
  font-family: 'Rowan-Variable';
  src: 
    url('/fonts/Rowan-Variable.woff2') format('woff2'),
    url('/fonts/Rowan-Variable.woff') format('woff'),
    url('/fonts/Rowan-Variable.ttf') format('truetype');
  font-weight: 300 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Rowan-VariableItalic';
  src: 
    url('/fonts/Rowan-VariableItalic.woff2') format('woff2'),
    url('/fonts/Rowan-VariableItalic.woff') format('woff'),
    url('/fonts/Rowan-VariableItalic.ttf') format('truetype');
  font-weight: 300 700;
  font-display: swap;
  font-style: italic;
}
